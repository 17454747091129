.profile-table {
    table-layout: fixed;
    width: 100%;
}

.profile-table th:nth-child(1),
.profile-table td:nth-child(1) {
    width: 20%;
}

.profile-table th:nth-child(2),
.profile-table td:nth-child(2) {
    width: 40%
}

.profile-table th:nth-child(3),
.profile-table td:nth-child(3) {
    width: 40%;
}