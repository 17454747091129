/*@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700');*/





@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');

@import url('https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.2.1/css/bootstrap.min.css');

body{ color:#303030; font-family: "Mulish", sans-serif; }
.form-check-input:focus { border-color: #86b7fe; outline: 0; box-shadow:none; }

.fddsaf{ float:left; width:100%; }
.header.navbar-fixed-top { position: absolute; z-index: 1; width: 100%; color: #fff; margin-top: 25px; }
.header.navbar-fixed-top button { padding: 0 25px; border: none !important; width: auto; min-width: auto; }
.header.navbar-fixed-top .MuiTabs-indicator { display: none; }
#navbarNavDropdown a { color: #fff; }
#navbarNavDropdown a.active{ font-weight:bold; }

.banner { float: left; width: 100%; position: relative; margin-bottom: 90px; }
.banner img { width: 100%; object-fit: cover; }
.banner .banner_caption { position: absolute; top: 50%; transform: translateY(-50%); }
.banner .banner_caption h2 { font-size: 55px; font-weight: 900; color: #fff; margin: 0 0 25px 0; }
.banner .banner_caption h2 span { color: #5CC2FF; }
.banner .banner_caption ul li { margin-right: 20px; }

.MuiTabs-scroller.MuiTabs-fixed .nav-link {
  padding-left: 20px;
  padding-right: 20px;
}


.col_loop { float: left; width: 100%; text-align: center; }
.col_loop img { margin: 0 0 30px 0; }
.col_loop h2 { font-size: 33px; font-weight: 700; min-height: 78px; }
.col_loop p { margin: 0 0 28px 0; }
.col_loop .FormField img { margin: 0; }




.footer{ background:#fff url(assets/img/newimages/footerbg.png); background-size:100% 100%; padding:130px 0 37px 0; }
.footer_logo { float: right; margin-bottom: 58px; }
.footer_logo img { width: 180px; height: 50px; float: right; }

.footer_nav li { border-right: 1px solid #fff; height: 45px; align-items: center;  display: flex; }
.footer_nav li:last-child { border-right: none; }
.nav.nav-justified.footer_nav li a{ float:left; width:100%; padding:0; color:#fff; text-decoration:none; }
.nav.nav-justified.footer_nav li a span { width: 100%; float: left; }

.bottom_foot{ margin-top:40px; }
.store_links li{ margin-right:13px;   }

.store_links li:first-child { order: 2; }
.store_links li a { padding: 0; }
.store_links img {}


.copyright_area { text-align: right; color: #fff; }
.copyright_area p { margin: 0; display: flex; align-items: center; justify-content: end; }
.copyright_area p a { margin-left: 10px; }





.about_payments { float: left; width: 100%; }
.about_payments h3 { font-size: 30px; font-weight: 700; color: #0F8FE5; }
.about_payments p { margin: 0 0 40px 0; }
.about_payments p.last { margin: 0; }
.about_payments img{ width:50%; }
.about_payments ul{ list-style:none; padding:0px; margin:15px 0px 0px 0px; }
.about_payments ul li { position: relative; padding: 0 0 5px 40px; }
.about_payments ul li::after { position: absolute; content: ""; left: 15px; top: 8px; width: 12px; height: 12px; background: url(assets/img/polygon.png); background-size:100%; }



.img__wrap { float: left; width: 100%; text-align: center; }
.img__wrap img { width: 303px; height: 303px; border: 3px solid #0F8FE5; padding: 10px; margin: 0px auto; display: block; float: none; }
.img__wrap h2 { width: 100%; float: left; font-size: 30px; margin: 20px 0 0 0; font-weight: 500; }

.about_name .title{ color:#0F8FE5; font-weight:600; font-size:40px; }



.left_side {
  width: 100%;
  float: left;
  background: #ececec;
  height: 100vh;
  position: relative; overflow: hidden;
}
.left_side img {
  width: 100%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}



.right_side_form { width: 100%; height: 100%; position: relative; overflow: scroll; overflow-x: hidden; }
.right_side_form .login_form { position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); width:80%; }
.right_side_form .login_form img { margin: 0px auto; display: block; }

.right_side_form .login_form h2 { text-align: center; font-weight: 800; font-size: 40px; color: #0F8FE5; margin: 15px 0 40px 0; }
.right_side_form .login_form .FormField { float: left; width: 100%; }
.right_side_form .login_form .FormField:first-child { margin-bottom: 25px;}
.right_side_form .login_form .FormField label { float: left; width: 115%; font-weight: 600; margin-bottom: 5px; }
.right_side_form .login_form .FormField input { width: 100%; height: 40px; border: 1px solid #D9D9D9; padding: 0 10px; }
.right_side_form .login_form .FormField button { border: none; background: #5CC2FF; color: #fff; border-radius: 50px; width: 80%; padding: 8px 0; box-shadow: 0 2px 3px #ccc; margin:0px auto; display:block; text-transform:capitalize !important; font-weight:bold; font-size:20px; }
.right_side_form .login_form .FormField a { text-decoration: none; text-align: right; width: 100%; float: left; font-weight: 500; color: #303030; }


.right_side_form .login_form .FormField.btns { margin-top: 20px; }

.right_side_form .login_form .FormField_AlternateLink { float: left; width: 100%; }
.right_side_form .login_form .FormField_AlternateLink span { float: left; width: 100%; text-align: center; font-weight:600; color:#303030; }
.right_side_form .login_form .FormField_AlternateLink span a { color: #D94452; }
.right_side_form.sing_up .FormField { margin: 0 0 10px 0 !important; }


.right_side_form.sing_up .FormField { margin: 0 0 14px 0 !important; width: 47%; }
.right_side_form.sing_up .FormField:first-child { width: 100%; }
.right_side_form.sing_up .FormField:nth-child(2n+1) { float: right; }
.right_side_form.sing_up .FormField.btnsup { width: 100%; margin-top: 20px !important; }

/* file upload button */
input[type="file"]::file-selector-button {
  border-radius: 4px;
  padding: 0 16px;
  height: 40px;
  cursor: pointer;
  background-color: white;
  border: #ECECEC;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  margin-right: 16px;
  transition: background-color 200ms; background: #ECECEC;
}

/* file upload button hover state */
input[type="file"]::file-selector-button:hover {
  background-color: #ECECEC;
}

/* file upload button active state */
input[type="file"]::file-selector-button:active {
  background-color: #ECECEC;
}

.right_side_form .login_form .FormField input[type="file"] {
  padding: 0; border: none;
}

.right_side_form .login_form .FormField.full { width: 100%; }
.right_side_form.sing_up .FormField .form-check { float: left; width: 47%; border: 1px solid #D9D9D9; height: 40px; padding: 7px 35px; }
.right_side_form.sing_up .FormField .form-check:last-child { float: right; }
.right_side_form .login_form .FormField input.form-check-input { width: 16px; height: 16px; padding: 0; float: left; background: #D9D9D9; border-color: #D9D9D9; }
.right_side_form .login_form .FormField .form-check-input:checked[type="radio"] { background: #5cc2ff; border-color: #5cc2ff; filter: brightness(100%); }
.right_side_form .login_form.signup_wrpa { position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); width: 80%; padding: 25px 20px 25px 0; }


.right_side_form.singin { overflow: inherit; }



.privacy-document h2 { font-size: 24px; font-weight: bold; color: #0F8FE5; margin: 12px 0 20px 0; float: left; width: 100%; }
.privacy-document h1 { text-decoration: underline; font-weight: bold; font-size: 30px; color: #3A3A3A; margin: 10px 0 20px 0; float: left; width: 100%; }
.privacy-document a { color: #3A3A3A; }


p.notification { float: left; width: 100%; text-align: center; margin: 10px 0 0 0; }

button.print_btn { background: #4cccfe; border: none; color: #fff; font-weight: 600; font-size: 23px; padding: 10px 25px; border-radius: 50px; text-transform: capitalize; box-shadow: 1px 3px 8px #ccc; margin-bottom: 30px; }

header.MuiPaper-root.MuiAppBar-root { border-bottom: 1px solid #D9D9D9 !important; }
header.MuiPaper-root.MuiAppBar-root button#personIcon { background: #4BBEFE; color: #fff; border-radius: 50px; width: 67px; height: 67px; margin: 0; box-shadow:0 2px 6px #ccc; }
header.MuiPaper-root.MuiAppBar-root button#personIcon svg { width: 50px; height: 50px; margin: 0px auto; display: block; text-align: center; right: inherit; }


.search-wrapper {
  display: flex;
  justify-content: end;
  align-items: center; margin-bottom: 20px;
}

.search-wrapper .process-btn {
  background: #50C5FE;
  color: #fff;
  text-decoration: none;
  width: 200px;
  text-align: center;
  border-radius: 4px;
  font-weight: 900;
  padding: 17px 0; box-shadow: 0 2px 6px #ccc;
}
.search .MuiFormControl-root.undefined {
  margin: 0;
}

.search-wrapper .search {
  margin-right: 20px;
}

.search-wrapper .search button {
  box-shadow: none;
  margin: 0; background: transparent;
}


.search-wrapper .search .MuiInputBase-root.MuiInput-root.MuiInputBase-formControl.MuiInput-formControl::before {
  width: 122%;
  left: -6px;
}


/*#transactionContainer label {
  transition: none;
  position: inherit;
  bottom: 0;
  transform: inherit;
  color: #303030 !important;
  font-size: 16px;
}

#transactionContainer input {
  border: 1px solid #D9D9D9;
  padding: 15px;
}*/

#transactionContainer .MuiFormControl-root {
  margin: 25px 0 0px 0;
}
#transactionContainer .MuiFormControl-root .MuiInput-formControl {
  margin: 7px 0 0 0;
}

#transactionContainer .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12 .MuiButtonBase-root.MuiButton-root.MuiButton-text {
  float: right;
  background: #EAEAEA;
  box-shadow: none;
  border-radius: 0;
  color: #7A7A7A;
  font-weight: 900;
  font-size: 16px; height: 40px; margin: -11px 0 0 0;
}


#transactionContainer .CardFooter-cardFooter-121 .MuiButtonBase-root.MuiButton-root {
  text-transform: capitalize;
  font-size: 24px;
  font-weight: 500;
  width: 400px;
  height: 53px;
  margin: 0 0 0 20%;
}
#transactionContainer .MuiGrid-root.GridContainer-grid-109.MuiGrid-container.MuiGrid-grid-xs-12:first-child {
  margin-left: 0 !important;
  width: 50% !important;
}

.ReactTable .rt-thead .rt-resizable-header {
  border-color: #fff;
}
.Dashboard-mainPanel-251.ps.ps--active-y .Dashboard-content-252 {
  margin-top: 0 !important;
}

.ReactModal__Overlay {
  z-index: 11111 !important;
}
.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary {
  border-radius: 50px;
  background: #4CCCFE !important;
  box-shadow: 0 2px 1px #0000003b;
  font-size: 21px;
  font-weight: 600;
  text-transform: capitalize;
  width: 284px; color: #fff;
}


.MuiFormControl-root label {
  position: inherit;
  transition: none;
  transform: inherit; color: #3A3A3A !important;
}

.MuiFormControl-root {
  margin: 0 0 20px 0 !important;
  padding: 0 !important;
}

.MuiFormControl-root input {
  width: 100%;
  height: 40px;
  border: 1px solid #D9D9D9;
  padding: 0 10px;
}

.ReactModalPortal h3 {
  margin: 15px 0 30px 0;
}

.search-wrapper .search .MuiInputBase-input.MuiInput-input {
  border: none;
}


.new_sidebar ul a {
  margin: 0 5px; border-top: 1px solid #fff;
}
.new_sidebar ul a:nth-child(5n) {
  border-bottom: 1px solid #fff;
}
.new_sidebar ul a .MuiListItem-gutters.MuiListItem-button {
  margin: 5px 0; display: flex;
}
.new_sidebar ul a .MuiListItem-gutters.MuiListItem-button .MuiListItemText-root {
  word-break: break-all;
}

.new_sidebar ul a:first-child{ border-top:none; }
.new_sidebar ul a:last-child{ border-top:1px solid #fff; border-bottom:none; }


.MuiButtonBase-root.MuiListItem-root.Sidebar-itemLink-16.MuiListItem-gutters.MuiListItem-button{ margin-top:5px; margin-bottom:5px; display:flex; word-break:break-all; }

.MuiGrid-root.GridContainer-grid-163.MuiGrid-container.MuiGrid-grid-xs-12:first-child { width: 48% !important; }
.link_new_add {
  display: flex;
  word-break: break-all;
  border-top: 1px solid #fff !important;
  margin:5px !important;
  width: 96% !important;
}

.ab_body {  }
.ab_profile .main-card-c { margin: 0; text-align: left; }
.ab_profile .ab_body { margin-top: 30px; text-align: left; box-shadow: 0 1px 5px #E6E6E6; border-radius: 5px; padding: 30px; float: left; width: 100%; }
.ab_profile .ab_body h6 { margin: 0 0 24px 0; font-weight: 400; color: #3A3A3A; }
.ab_profile .ab_body h6 span { width: 250px; font-weight: bold; display: inline-block; }
.ab_profile .ab_body h4 { margin-bottom: 30px; font-weight: bold; font-size:30px; }
.ab_profile .abt_wpas { margin-bottom: 15px; }

.ab_profile .btn_upd { width: 152px; height: 42px; background: #3AB0F6; border: none; color: #fff; font-size: 16px; font-weight: 800; border-radius: 5px; }

.agent_name { display: flex; align-items: center; position:relative; }
.agent_name .name_title { font-weight: 500; color: #303030; margin-right: 10px; font-size: 20px;}



.Dashboard-content-143{ margin-top:0px; }

.ab_profile .btn_upd { margin-top: 20px; }


.car_infogrid {
  float: left;
  width: 50%;
  position: relative;
  border-bottom: 1px solid #D9D9D9;
  padding-bottom: 30px;
  margin-bottom: 20px !important;
}
.car_infogrid .loop_info { float: left; width: 100%; margin-bottom: 8px; }
.car_infogrid .loop_info b { width: 165px; display: inline-block; }
.botton_info {  display: inline-block; }
.mg_top{ margin-top: 30px; }


.car_infogrid.img img { position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); }
.car_infogrid .loop_info input[type=file] { border: 1px solid #F5F5F5; }



.agent_name .MuiPaper-rounded { border-radius: 0 !important; }
.agent_name .MuiPaper-rounded .MuiList-root.MuiList-padding { padding: 10px 8px; }


.btn_wpas button {
  background: #50C5FE !important;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 900;
  box-shadow: 1px 2px 4px #0000004d;
  height: 50px;
  margin-right: 10px;
}

.search-wrapper .search .btn_vehic {
  background: #50c5fe;
  font-weight: 900;
  text-transform: capitalize;
  padding: 17px 0;
  width: 210px;
  font-size: 16px;
  margin-left: 22px;
}

.search-wrapper .search button .MuiSvgIcon-root{ stroke: #808080; }
#transactionContainer .main-card-c.Card-card-92 {
  margin-top: 0;
}

.billing_wrapper label {
  color: #303030;
}
.billing_wrapper .MuiSelect-select.MuiSelect-select {
  padding-right: 24px;
  width: 200px;
  border: 1px solid #D9D9D9 !important;
  padding: 12px 20px;
  margin-top: 7px;
}
.billing_wrapper .MuiInputBase-root.MuiInput-root.MuiInput-underline:before{ display:none; }

.login_singup_wrapper{ overflow:hidden; padding-left:0; }
.login_singup_wrapper.sngup{ overflow-x:hidden; }



.sngup .right_side_form{ overflow: inherit; }

.sngup .right_side_form .login_form.signup_wrpa {
  position: inherit;
  top: inherit;
  left: inherit;
  transform: inherit;
  padding: 30px 0;
  margin: 0px auto 30px !important;
  display: block;
  height: 100%;
}

.container-fluid.login_singup_wrapper.sngup .left_side {
  height: 100%;
} 


@media only screen and (min-width: 320px) and (max-width: 680px) {

  #navbarNavDropdown a {
    color: #000;
}

  .agent_name { display: block; }
  .agent_name .name_title{ display:none; }


  .header.navbar-fixed-top{ position:relative; margin-top:0; padding-left:15px; }

  .banner { margin-bottom: 20px; }
  .banner .banner_caption h2 { font-size: 20px; margin: 0 0 25px 0; }
  .banner .banner_caption li img { width: 100px; }

.about_payments h3 { font-size: 22px; }


  .col_loop{ margin-bottom:20px; }
  .col_loop img { margin: 0 0 10px 0; width: 35%; }
  .col_loop h2{ min-height:auto; font-size:22px; }
  .col_loop p { margin: 0 0 20px 0; }

  footer.footer { background: #2888C9; padding: 30px 0; }
  .footer_logo { margin-bottom: 20px; float: none; }
  .footer_logo img { width: 180px; height: 50px; float: none; margin: 0px auto; display: block; }

  .store_links ul.nav { justify-content: center; }

  .bottom_foot { margin: 20px 0 0; }
  .copyright_area { text-align: center; margin-top: 20px; }

  .footer_nav li.nav-item { border: none; float: left; width: 50%; margin-bottom: 10px; display: inherit; flex-basis: auto; height: auto; }


  .left_side img { height: auto; }

  .right_side_form .login_form { position: inherit !important; transform: inherit !important; left: 0 !important; top: 0 !important; margin: 40px auto; }

  .about_payments { margin-bottom: 30px; }

  .about_name .title{ text-align:center; }
  .img__wrap { margin-bottom: 20px; }
  .left_side{ display:none; }


  #transactionContainer .MuiGrid-root.GridContainer-grid-109.MuiGrid-container.MuiGrid-grid-xs-12:first-child { width: 100% !important; }
  #transactionContainer .CardFooter-cardFooter-121 .MuiButtonBase-root.MuiButton-root { width: 100%; margin: 0; height: auto; font-size: 19px; }
  .search-wrapper{ display:block; }
  .search-wrapper .process-btn{ padding-left:20px; padding-right:20px; }

  .car_infogrid{ width:100%; }
  .car_infogrid.img img { position: inherit; }

  .search-wrapper .search .btn_vehic { margin: 0 0 30px; }

  .new_sidebar ul a.active div {
    background: #fff; color: #0F8FE5;
}

}

@media only screen and (min-width: 320px) and (max-width: 916px) and (orientation: landscape) {


  .right_side_form .login_form{ width:100%; }
  .right_side_form .login_form { overflow-y: auto; padding: 25px 0; height: 100%; width: 100%; }
  .right_side_form .login_form.signup_wrpa { padding-right: 0; width: 100%; }
  .right_side_form.sing_up .FormField .form-check{ padding:7px 5px 7px 25px; }

  footer.footer { background: #2888c9; padding-top: 40px; margin-top: 30px; }
  .col_loop img { margin: 0 0 30px 0; width: 100%; }
  .col_loop p br { display: none; }
}


@media only screen and (min-width: 768px) and (max-width: 1024px)  {
  .col_loop img { width: 100%; }
  .col_loop p br{ display:none; }
  .banner { margin-bottom: 25px; }
  .banner .banner_caption h2 { font-size: 35px; }
  .banner .banner_caption li img { width: 78%; }
  .footer { background-size: 100% 100%; background-repeat: no-repeat; }

  .img__wrap img{ width:100%; height:auto; }
  .img__wrap h2{ font-size:26px; }
}

a.a-href{
  cursor: pointer;
  color: #0F8FE5;
  text-decoration: none;
}

a.a-href:hover{
  cursor: pointer;
  color: #085886;
}