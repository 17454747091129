#timelineContainer{ overflow:hidden; }

.main-timeline .timeline .img {
    float: right;
    width: 47.2%; text-align: center;
}
.main-timeline .timeline .img img {
    width: 100%;
    height: 290px;
    object-fit: scale-down;
}



.main-timeline .timeline { float: left; width: 100%; padding: 0 0 0 0px; position: relative; display: flex; align-items: center; }
.timeline .timeline-content { background: #00DDF8; color: #fff; border: none; float: left; width: 53%; padding: 30px 50px 30px 50px; position: relative; height: 320px; font-size: 14px; }
.main-timeline .timeline:nth-child(2n+2) .timeline-content { float: right; }


.timeline .timeline-content .center_div { position: absolute; top: 50%; transform: translateY(-50%); width: 85%; }
.timeline .timeline-content .center_div .description { float: left; width: 100%; }
.timeline .timeline-content .center_div button { color: #0F8FE5; background: #FFFFFF; border: none; border-radius: 50px; font-size: 12px; padding: 1px 20px; float: right; margin-top: 20px; font-weight: normal; }
.timeline .timeline-content .center_div .description ul { margin: 0; padding: 0; list-style: none; }
.timeline .description li { list-style: none; }
.timeline .description li a { text-decoration: none; color: #fff; }
.timeline .timeline-content p { margin: 0; }
.timeline .timeline-content p.time p { float: left; margin-right: 4px; }


.main-timeline .timeline:nth-child(odd){ flex-direction: row-reverse; }



.main-timeline .timeline:nth-child(6n+1) .timeline-content{ background: linear-gradient(90deg, #00DDF8 0%, #00CAE3 100%); border-right:70px solid #00DDF8; }
.main-timeline .timeline:nth-child(6n+2) .timeline-content{ background: linear-gradient(-90deg, #01ABE8 0%, #0093C8 100%); border-left:70px solid #01ABE8; }
.main-timeline .timeline:nth-child(6n+3) .timeline-content{ background: linear-gradient(90deg, #0087CA 0%, #0179B5 100%); border-right:70px solid #0087CA; }
.main-timeline .timeline:nth-child(6n+4) .timeline-content{ background: linear-gradient(-90deg, #00639C 0%, #0076BA 100%); border-left:70px solid #00639C; }
.main-timeline .timeline:nth-child(6n+5) .timeline-content{ background: linear-gradient(90deg, #013C67 0%, #002F51 100%); border-right:70px solid #013C67; }
.main-timeline .timeline:nth-child(6n+6) .timeline-content{ background: linear-gradient(-90deg, #FD0002 0%, #D90002 100%); border-left:70px solid #FD0002; }



.timeline-content::after { position: absolute; content: ""; bottom: -34px; left: -75px; background: url(../../assets/img/bt_bot_left.png); height: 40px; width: 268px; z-index: 11; }
.main-timeline .timeline:nth-child(odd) .timeline-content::after{ background: url(../../assets/img/bg_bot.png); right: -75px; left:inherit; }







.main-timeline .timeline:nth-child(2n+2)::before{ left:19% }

.main-timeline .timeline::after {  z-index: -1; left: 143px; height: 121%; width: 2px; rotate: -34deg; top: 144px; border-left: 2px dashed #787676; }
.main-timeline .timeline:nth-child(2n+2)::after { rotate: 34deg; left: 130px;}
.main-timeline .timeline:last-child::after { display:none; }




.MuiToolbar-root.MuiToolbar-regular ul li { padding: 5px 20px !important; }
.ReactTable .rt-tbody .rt-tr-group button { color: #D94452; text-decoration: underline; background: transparent; border: none; text-align: center; margin: 0px auto; display: block; font-weight: 600; }

.ReactTable .rt-tbody .rt-td { border-right: none; align-items: center; display: flex; padding: 21px 10px; }
.ReactTable .rt-tbody .rt-td .album-img { height: 28px; margin: 0px auto; border-radius: 0 !important; max-width: inherit !important; }


.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-12.MuiGrid-grid-md-12 .CardHeader-cardHeader-108.CardHeader-primaryCardHeader-116 {  margin: 0; }
header.MuiPaper-root.MuiAppBar-root{ border-bottom:none !important; }
.MuiGrid-root.GridItem-grid-94.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-12.MuiGrid-grid-md-12 .Card-card-104 { box-shadow: none; padding-left: 0; }
.MuiGrid-root.GridItem-grid-94.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-12.MuiGrid-grid-md-12 .CardBody-cardBody-122 { box-shadow: none; border: none; padding-left: 0; padding-right: 0; }

.Sidebar-background-13 { background: #50C5FE; background-image: none !important; }
    .Sidebar-background-13::after { background: rgb(80,197,254) !important; background: linear-gradient(180deg, rgba(80,197,254,1) 44%, rgba(49,143,199,1) 81%) !important; }

    .Sidebar-img-12{ margin:0px !important; }
    .Sidebar-logo-8:after{ display:none; }

    ul.MuiList-root.Sidebar-list-14.MuiList-padding { margin: 0; }
    ul.MuiList-root.Sidebar-list-14.MuiList-padding br{ display:none; }


@media only screen and (min-width: 320px) and (max-width: 680px) {



div#transactionContainer .MuiGrid-root.MuiGrid-container.MuiGrid-grid-xs-12 { width: 100% !important; margin: 0 0 30px 0 !important; }
div#transactionContainer .MuiGrid-root.MuiGrid-container.MuiGrid-grid-xs-12:last-child { margin: 0 !important; }

div#transactionContainer .CardBody-cardBody-131 { padding-left: 0; padding-right: 0; }

div#transactionContainer .CardFooter-cardFooter-121 { width: 100%; text-align: center; padding: 0 !important; }
div#transactionContainer .MuiBox-root.MuiBox-root-126 { margin: 0 auto; }



    button#personIcon { padding: 0; }
    button#personIcon span.MuiButton-label { justify-content: flex-end; }



    .timeline .timeline-content{ padding:10px; font-size:9px; border-width:20px !important; width: 54%; }
    .timeline .timeline-content .center_div button{ font-size:8px; margin-top:10px; }

    .timeline-content::after { bottom: -11px; left: -20px; height: 12px; width: 62px; background-size: 100% !important; background-repeat: no-repeat !important; }
    .main-timeline .timeline:nth-child(2n+1) .timeline-content::after { right: -21px; }

    button.print_btn { font-size: 10px !important; padding: 6px 20px !important; }
}


/* Portrait */
@media only screen and (min-width: 320px) and (max-width: 680px) and (orientation: portrait) {
    header.MuiPaper-root.MuiAppBar-root button { font-size: 24px !important; }
    .main-timeline .timeline{ padding:0; }
    

    
}


@media only screen and (min-width: 768px) and (max-width: 1024px)  { 

.timeline .timeline-content { padding-left: 20px; padding-right: 10px; width: 69%; height:400px; }
.main-timeline .timeline .img{ width:47%; }



 }