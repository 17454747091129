
.new_sidebar .sidebar-bg,.new_sidebar .Sidebar-background-13 { background: #50C5FE; background-image: none !important; }
.new_sidebar .sidebar-logo img,.new_sidebar .Sidebar-logo-8 img { margin: 0; }
.new_sidebar .sidebar-bg::after,.new_sidebar .Sidebar-background-13::after{ background: rgb(80,197,254); background: linear-gradient(180deg, rgba(80,197,254,1) 44%, rgba(49,143,199,1) 81%); }
.new_sidebar .sidebar-draw-paper,.new_sidebar .Sidebar-drawerPaper-6{ box-shadow: none;}
.new_sidebar .sidebar-wrapper br,.new_sidebar .Sidebar-sidebarWrapper-27 br{ display: none;}
.new_sidebar .sidebar-logo::after,.new_sidebar .Sidebar-logo-8:after{ display: none;}
.new_sidebar .sidebar-wrapper .active .MuiButtonBase-root,.new_sidebar .Sidebar-sidebarWrapper-27 .active .MuiButtonBase-root{ background: #fff; border-radius: 0px; box-shadow: none;}
.new_sidebar .sidebar-wrapper .MuiButtonBase-root .MuiListItemText-root,.new_sidebar .Sidebar-sidebarWrapper-27 .MuiButtonBase-root .MuiListItemText-root { font-size: 17px; font-weight: 500; }
.new_sidebar .sidebar-wrapper .active .MuiButtonBase-root .MuiListItemText-root,.new_sidebar .Sidebar-sidebarWrapper-27 .active .MuiButtonBase-root .MuiListItemText-root { color: #0F8FE5; }
.new_sidebar .sidebar-wrapper .active svg,.new_sidebar .Sidebar-sidebarWrapper-27 .active svg{ color: #0F8FE5;}



.rt-thead.-header { background: linear-gradient(180deg, rgba(80,197,254,1) 44%, rgba(49,143,199,1) 81%); color: #fff; }
.rt-thead.-header  .rt-resizable-header-content { padding: 14px 0; font-weight: 700; }
.rt-th.rt-resizable-header.-cursor-pointer { border-color: #fff; }

.ReactTable .rt-thead.-filters .rt-th input { border-color: #D9D9D9; height: 40px; }
.ReactTable .rt-tbody .rt-tr-group { border-bottom: none; }
.ReactTable .rt-tbody .rt-tr-group:nth-child(2n+2) { background:#E5F6FE; }



#dashboardTable .main-card-c { margin: 0; box-shadow: none; }
#dashboardTable .CardBody-cardBody-131{ padding: 0; }


#dashboardTable .main-card-c .main-card-header-c, #dashboardTable .main-card-c .CardHeader-cardHeader-117.CardHeader-primaryCardHeader-125 {
    margin: 0;
    box-shadow: none;
    border-radius: 0;
    background: transparent;
    padding-right: 0;
    padding-top: 0;
}

#dashboardTable .MuiTabs-scrollable{ justify-content: end; display: flex; }
#dashboardTable .MuiTabs-scrollable button { background: #50C5FE; width: 150px !important; height: 55px !important; font-weight: bold; }

.main-card-c { box-shadow: none !important; }
.CardHeader-cardHeader-117.CardHeader-primaryCardHeader-125{ margin: 0 !important;}
.Dashboard-container-4 .GridItem-grid-103 { padding: 0px !important; }
#dashboardTable.GridContainer-grid-134 { margin: 0px !important; }


.main-card-c .main-card-header-c, #dashboardTable .main-card-c .CardHeader-cardHeader-117.CardHeader-primaryCardHeader-125 {
    background: #50C5FE !important;
    border-radius: 5px !important;
    box-shadow: none !important;
    width: 294px !important;
    text-align: center;
    font-size: 23px !important;
    margin:0 0 30px !important; box-shadow: 0 2px 6px #ccc !important;
}

.CardHeader-cardHeader-96.CardHeader-primaryCardHeader-104.main-card-header-c {
    border-radius: 5px !important;
}




.CardBody-cardBody-106 { padding-left: 0 !important; padding-right: 0 !important; }
.CardBody-cardBody-106 label { position: inherit; transform: inherit; color: #303030 !important; }
.CardBody-cardBody-106 input { border: 1px solid #D9D9D9; padding: 10px 10px; min-height: auto; height: auto; }
div#transactionContainer .Card-card-92 { box-shadow: none; }

.CardBody-cardBody-106 .MuiInputBase-root { margin: 8px 0 0 0; }
.CustomInput-formControl-120{ margin: 0 !important; padding: 0 !important;}


.GridItem-grid-110 button {
    background: #EAEAEA !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    color: #7A7A7A !important; float: right;
}




#transactionContainer .drop_area {
    background: #4cccfe1c;
    height: 290px; position: relative; float: left; width: 100%;
}

#transactionContainer .drop_area .MuiGrid-root.dropzone-grid-section {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    text-align: center;
}
#transactionContainer .drop_area .MuiGrid-root.dropzone-grid-section p{ margin: 0px;}
#transactionContainer .drop_area .MuiGrid-root.dropzone-grid-section h4 {
    font-size: 24px;
    color: #349BD9;
    font-weight: bold;
    margin: 15px 0 0 0 !important;
}



.CardFooter-cardFooter-121 {
    margin: 0px !important;
}
.MuiBox-root.MuiBox-root-126 {
    margin: 0;
}

button.btn-btn-btn {
    background: #5CC2FF;
    border-radius: 50px;
    padding: 9px 60px;
    box-shadow: 0 2px 6px #ccc;
    text-transform: capitalize;
    font-size: 24px;
    font-weight: 500;
    width: 400px;
    height: 53px;
    margin: 0 0 0 20%;
}

.max-www{
    width: 151px !important;
    margin: 10px auto !important;
    text-align: center !important;
    display: block;
}

div#transactionContainer .CardBody-cardBody-106 .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12 { margin-bottom: 25px; }
div#transactionContainer .CardBody-cardBody-106 .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12:first-child { margin-bottom: 0px; }
.CardHeader-cardHeader-96.CardHeader-primaryCardHeader-104 {
    margin: 0;
}

.CardHeader-cardHeader-96.CardHeader-primaryCardHeader-104 {
    margin: 0;
    background: #50C5FE;
    border-radius: 0 !important;
}


.vin-card {
    background: #50C5FE !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    width: 294px !important;
    text-align: center;
    font-size: 23px !important;
    margin:0 0 30px !important;
}



div#transactionContainer .CardBody-cardBody-106 .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12 .decode_tag { color: red; margin-top: 10px; text-transform: capitalize; }
p.drop_area_tagline { margin: 20px 0 0 0; }


.billing_wrapper {
    float: left;
    width: 100%;
}

.billing_wrapper h4 {
    color: #349BD9;
    font-weight: bold;
    font-size: 18px;
    margin: 0 0 20px 0;
    float: left;
    width: 100%;
}

.billing_wrapper ol.billing_listing {
    border-bottom: 1px solid #D9D9D9;
    padding-bottom: 30px;
    margin-bottom: 30px; margin-top: 30px;
}

.billing_wrapper ol li{ margin-bottom: 10px;}

header.MuiPaper-root.MuiAppBar-root {
    position: inherit;
    border-bottom: 1px solid #D9D9D9;
    border-radius: 0;
    margin: 0 15px;
    width: auto;
}

header.MuiPaper-root.MuiAppBar-root button {
    color: #0F8FE5;
    font-size: 40px;
    font-weight: bold;
    opacity: 1;
    padding: 0;
}


header.MuiPaper-root.MuiAppBar-root 
 .MuiToolbar-root.MuiToolbar-regular.Header-container-45 {
    padding: 0;
}

header.MuiPaper-root.MuiAppBar-root {
    position: inherit;
    border-bottom: 1px solid #D9D9D9;
    border-radius: 0;
    margin: 0 15px;
    width: auto;
    padding: 50px 0 18px 0;
}

.Dashboard-content-3{ margin-top: 0px !important;}


.new_sidebar ul a span{ width: 25px !important; float: left; height: 25px; background-size: 100%; margin: 4px 18px 0 12px;}
.new_sidebar ul a span.Archive { background: url("assets/img/archive.png") no-repeat;  }
.new_sidebar ul a span.Dashboard { background: url("assets/img/dashboard.png") no-repeat;  }
.new_sidebar ul a span.New.VIN-User { background: url("assets/img/new_user.png") no-repeat;  }
.new_sidebar ul a span.Billing { background: url("assets/img/billing.png") no-repeat;  }

.new_sidebar ul a.active span.Archive { background: url("assets/img/archive-active.png") no-repeat;  }
.new_sidebar ul a.active span.Dashboard { background: url("assets/img/dashboard-active.png") no-repeat;  }
.new_sidebar ul a.active span.New.VIN-User { background: url("assets/img/new_user-active.png") no-repeat;  }
.new_sidebar ul a.active span.Billing { background: url("assets/img/billing-active.png") no-repeat;  }


svg.MuiSvgIcon-root {
    height: 30px;
    position: absolute;
    right: 8px;
    top: 13px;
}