#pdfDocument {
    overflow: auto;
    width: 600px;
    margin-right: 20px;
    margin-left: 45px;
    border: 1px solid #333;
}

.container_row {
    display: grid;
}

.margin-left {
    margin-left: 20px;
}

.layer0 {
    margin-top: -10px;
}

.layer1, .layer2 {
    grid-column: 1;
    grid-row: 2;
}

div.react-confirm-alert-overlay {
    z-index: 9999;
}

.react-pdf__Document {
    margin: 0 auto;
    height: 1000px !important;
}
